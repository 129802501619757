import { Route } from '@angular/router';
import { authenticated, unauthenticated } from 'auth';
import { hasJob } from './modules/shifts/store/shifts.guards';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    canActivate: [unauthenticated('/')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [authenticated('/auth'), hasJob()],
    loadChildren: () =>
      import('./modules/shifts/shifts.module').then((m) => m.ShiftsModule),
  },
  { path: '**', redirectTo: '' },
];
